import styled from "styled-components";

const ColoredSeparator = styled.div`
  width: 80%;
  height: 2px;
  background-color: #f1ece9;
  margin-top: 12px;
  margin-bottom: 2vh;
  margin-left: 10%;
`;

export default ColoredSeparator;
