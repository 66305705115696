import React from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import { graphql } from "gatsby";
import TitleEffect from '../components/title-effect';
import Separator from '../components/separator';
import IfWas from '../components/if-was';
import ColoredSeparator from '../components/colored-separator';
import about1 from '../assets/images/about1.jpg';
import bannerEnd from '../assets/images/banner-end.jpg';
import bannerEndPortrait from '../assets/images/banner-end-portrait.jpg';
import bannerStart from '../assets/images/banner-start.jpg';
import bannerStartPortrait from '../assets/images/banner-start-portrait.jpg';
import film1 from '../assets/images/film1.jpg';
import film2 from '../assets/images/film2.jpg';
import jeanne from '../assets/images/jeanne.jpg';
import icon from '../assets/icon.png';
import Banner from '../components/banner';

import movie from '../assets/images/movie.jpg'
import music from '../assets/images/music.jpg'
import painting from '../assets/images/painting.jpg'
import quote from '../assets/images/quote.jpg'
import Meta from '../components/meta';


const Subtitle = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;

const Paragraph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
`;

const Text = styled.div`
  font-size: 1.2rem;
  max-width: 500px;
  text-align: justify;
  text-indent: 20px;
  padding: 12px;
`;

const Image = styled.img`
  max-width: 500px;
  height: auto;
  width: ${props => props.width ? `${props.width}px` : ''};
`;

const IfWasImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

// const Banner = styled.img`
//   width: 100%;
// `;

const Jeanne = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  flex-wrap: wrap;
`;

const Left = styled.img`
  width: 250px;
  height: auto;
`;

const Center = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > * {
    max-width: none;
  }
`;

const Right = styled.img`
  width: 250px;
  height: auto;
  align-self: flex-bottom;
  align-self: flex-end;
`;


const About = ({ data }) => {

  const { about, ifwas, logo, jeanne1, jeanne2 } = data;

  return (<Layout>
    <Meta title="A propos" />
    <ColoredSeparator />
    <Separator size={24} />
    <TitleEffect>{about.frontmatter.title}</TitleEffect>
    <Separator size={48} />
    <Subtitle>{about.frontmatter.subtitle1}</Subtitle>
    <Subtitle>{about.frontmatter.subtitle2}</Subtitle>
    <Separator size={48} />
    <Paragraph>
      <Text dangerouslySetInnerHTML={{ __html: about.html }} />
      <Image src={about1} />
    </Paragraph>

    <Separator size={96} />
    <TitleEffect>{ifwas.frontmatter.title}</TitleEffect>
    <Separator size={48} />

    <IfWasImages>
      <IfWas img={music} title={"Une musique"} description={ifwas.frontmatter.music} />
      <IfWas img={movie} title={"Un film"} description={ifwas.frontmatter.movie} />
      <IfWas img={painting} title={"Une peinture"} description={ifwas.frontmatter.painting} />
      <IfWas img={quote} title={"Une citation"} description={ifwas.frontmatter.quote} />
    </IfWasImages>

    <Separator size={96} />
    <TitleEffect>{logo.frontmatter.title}</TitleEffect>
    <Separator size={48} />
    <Paragraph>
      <Image src={icon} width={196} />
      <Text dangerouslySetInnerHTML={{ __html: logo.html }} />
    </Paragraph>

    <Separator size={96} />


    <Banner landscape={bannerStart} portrait={bannerStartPortrait} />

    <Separator size={96} />
    <TitleEffect>{jeanne1.frontmatter.title}</TitleEffect>
    <Subtitle>{jeanne1.frontmatter.description}</Subtitle>

    <Separator size={48} />

    <Jeanne>
      <Left src={film1} />
      <Center>
        <Text dangerouslySetInnerHTML={{ __html: jeanne1.html }} />
        <Banner portrait={jeanne} landscape={jeanne} />
        <Text dangerouslySetInnerHTML={{ __html: jeanne2.html }} />
      </Center >
      <Right src={film2} />
    </Jeanne>

    <Separator size={96} />

    <Banner landscape={bannerEnd} portrait={bannerEndPortrait} />
  </Layout>);
}


export const query = graphql`
  {
    about: markdownRemark(fileAbsolutePath: {regex: "/(about.md)/"}) {
      id
      html
      frontmatter {
        title
        subtitle1
        subtitle2
      }
    }
    ifwas: markdownRemark(fileAbsolutePath: {regex: "/(ifwas.md)/"}) {
      id
      html
      frontmatter {
        title
        music
        movie
        painting
        quote
      }
    }
    logo: markdownRemark(fileAbsolutePath: {regex: "/(logo.md)/"}) {
      id
      html
      frontmatter {
        title
      }
    }
    jeanne1: markdownRemark(fileAbsolutePath: {regex: "/(jeanne-part1.md)/"}) {
      id
      html
      frontmatter {
        title
        description
      }
    }
    jeanne2: markdownRemark(fileAbsolutePath: {regex: "/(jeanne-part2.md)/"}) {
      id
      html
    }
  }
`


export default About;