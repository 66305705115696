import React, { useState } from 'react';
import styled from 'styled-components';


const Container = styled.div`
  max-width: 300px;
  width: 300px;
  height: 300px;
  position: relative;
  background: url(${props => props.src});
  background-size: cover;
  position: relative;
`;

const Title = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 2rem;
  box-sizing: border-box;
  transition: height 0.5s;
  height: ${props => props.hovered ? '100%' : '42px'};
`;

const Description = styled.div`
  font-size: 1.3rem;
  padding: 8px;
`;

const IfWas = ({ img, title, description }) => {
  const [hovered, setHovered] = useState(false)

  const onHover = evt => {
    if ([...evt.target.classList].includes(Description.styledComponentId)) {
      return null;
    }
    setHovered(true);
  }

  const onClick = (evt) => {
    if ([...evt.target.classList].includes(Description.styledComponentId)) {
      return null;
    }
    setHovered(!hovered);
  }

  return (
    <Container src={img} onClick={onClick} onMouseEnter={onHover} onMouseLeave={() => setHovered(false)}>
      <Title hovered={hovered}>
        <div>{title}</div>
        <Description>{description}</Description>
      </Title>
    </Container>
  )
}

export default IfWas;
